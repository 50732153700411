<template>
  <div class="details" v-if="detailsContent">
    <div class="details-title">{{ detailsContent.title }}</div>
    <div class="details-content" id="details-content" v-html="detailsContent.content"></div>
    <div class="related">
      <div class="related-flex" v-for="item in articleses" :key="item.id" @click="btn(item.id)">
        <div class="adapt">
          <div
            class="flex-bg"
            :style="{ background: 'url(' + item.thumb + ' )', backgroundSize: 'cover' }"
          ></div>
          <div class="position"></div>
        </div>
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: '',
      detailsContent: {
        title: '',
        content: '',
      },
      articleses: [],
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    this.details();
    this.articles();
  },
  watch: {
    $route: function(to, from) {
      // 拿到目标参数 to.query.id 去再次请求数据接口
      this.id = to.params.id;
      this.details();
      this.articles();
    },
  },

  methods: {
    details() {
      return this.$request(Apis.details.DETAILS, { data: { id: this.id } }).then(data => {
        this.detailsContent = data.data.data;
        this.$nextTick(() => {
            setTimeout(() => {
              let Hwidth = $('#details-content').width();
              let imdwidth = $('#details-content p img').width();
              if (imdwidth >= Hwidth) {
                $('#details-content p img').css('width', '100%');
              }
              
            }, 10);
          });
      });
    },
    articles() {
      return this.$request(Apis.details.ARTICLES, { data: { id: this.id } }).then(data => {
        this.articleses = data.data.data;
      });
    },
    btn(id) {
      console.log(id);
      this.$router.push('/details/' + id);
    },
  },
};
</script>
<style lang="less">
#details-content {
  p {
    margin: 0;
    padding: 0;
    img{
      width: 100%;
    }
  }
}
</style>
<style lang="less" scoped>
.details {
  width: 90%;
  margin: 80px auto;
  .details-title {
    text-align: center;
    font-size: 24px;
    color: #ffcc00;
  }
  .details-content {
    width: 80%;
    margin: 50px auto;
    color: #fff;
    font-size: 16px;
    img {
      width: 100%;
    }
  }
  .related {
    display: flex;
    justify-content: space-between;
    align-content: center;
    .related-flex {
      width: 30%;
      .adapt {
        width: 100%;
        position: relative;
        .flex-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: auto;
        }
        .position {
          padding-bottom: 100%;
        }
      }

      p {
        color: #fff;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
</style>
