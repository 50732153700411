var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.detailsContent
    ? _c("div", { staticClass: "details" }, [
        _c("div", { staticClass: "details-title" }, [
          _vm._v(_vm._s(_vm.detailsContent.title))
        ]),
        _c("div", {
          staticClass: "details-content",
          attrs: { id: "details-content" },
          domProps: { innerHTML: _vm._s(_vm.detailsContent.content) }
        }),
        _c(
          "div",
          { staticClass: "related" },
          _vm._l(_vm.articleses, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "related-flex",
                on: {
                  click: function($event) {
                    return _vm.btn(item.id)
                  }
                }
              },
              [
                _c("div", { staticClass: "adapt" }, [
                  _c("div", {
                    staticClass: "flex-bg",
                    style: {
                      background: "url(" + item.thumb + " )",
                      backgroundSize: "cover"
                    }
                  }),
                  _c("div", { staticClass: "position" })
                ]),
                _c("p", [_vm._v(_vm._s(item.title))])
              ]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }